import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Paper, Link } from '@material-ui/core';
import { useBillingAccounts, useGetBillingJournal } from 'src/api/billingApi';
import { Table } from 'src/components/common/table';
import { typeBillingAccount } from 'src/types';
import { BillingJournalCreate } from 'src/components/BillingJournalCreate';
import { ContractorLabel } from 'src/components/ContractorLabel';
import { endOfDay, format, startOfDay } from 'date-fns';
import { useRouter } from 'src/hooks/useRouter';
import { BillingJournal } from 'src/components/BillingJournal';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      actions: {
        '& button': {
          marginLeft: theme.spacing(1),
        },
      },
    }),
  { name: 'CourierAccountsPage' },
);

export const CourierAccountsPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});

  const { query } = useRouter();

  const [sumAccMap, setSumAccMap] = useState<{
    [key: number]: typeBillingAccount | null;
  }>({});

  const variables = { accounts: '35,36,64,50,51' };

  const { data, loading, refetch, cancel } = useBillingAccounts({
    variables,
  });

  const billingJournalVariables = {
    ...variables,
    from: query.from || format(startOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    to: query.to || format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
  };

  const billingJournalRequest = useGetBillingJournal({
    variables: billingJournalVariables,
  });

  return (
    <div className={classes.root}>
      {data?.data?.map(account => (
        <Box key={account.id} mb={2}>
          <Paper>
            <Box p={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                fontSize="18px"
              >
                <Box>
                  <Link target="_blank" href={`/accounts/${account?.id}`}>
                    {account.name}
                  </Link>{' '}
                  <ContractorLabel contractor={account?.contractor} />
                </Box>
                <Box>
                  {![50, 51].includes(account?.id as number) &&
                    (account.balance
                      ? account.balance / 100
                      : 0
                    ).toLocaleString('ru-RU')}{' '}
                  {account.currency}
                </Box>
              </Box>
              <Box display="flex" mt={2} justifyContent="end">
                <BillingJournalCreate
                  onCreate={() => {
                    refetch(variables);
                    billingJournalRequest.refetch(billingJournalVariables);
                  }}
                  to={account}
                  withEmployee={false}
                  withCommission={false}
                />
                <Box ml={2}>
                  <BillingJournalCreate
                    add={false}
                    onCreate={() => {
                      refetch(variables);
                      billingJournalRequest.refetch(billingJournalVariables);
                    }}
                    from={account}
                    withEmployee={false}
                    withCommission={false}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      ))}
      <Box mt={2}>
        <BillingJournal
          journal={billingJournalRequest.data?.data}
          totalCount={billingJournalRequest.data?.totalCount}
          loading={billingJournalRequest.loading}
          cancel={billingJournalRequest.cancel}
        />
      </Box>
    </div>
  );
};
